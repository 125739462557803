<template>
    <div class="bg-white box-shadow border-r pb-20 px-20">
        <div class="d-flex-center border-bottom">
            <h4 class="mr-auto color-333">{{ $t('account.profile') }}</h4>
        </div>
        <h4>{{ $t('account.email') }}<span class="mx-8">:</span>{{ $store.state.userEmail }}</h4>
        <h4>{{ $t('register.invitation_code') }}<span class="mx-8">:</span>{{ $store.state.userInvitationCode }}</h4>
        <h4>KYC {{ $t('account.verify') }}<span class="mx-8">:</span><el-tag effect="plain" hit :type="verifyState[$store.state.userVerified][0]">{{ verifyState[$store.state.userVerified][1] }}</el-tag></h4>
    </div>
</template>

<script>
export default {
    data() {
        return {
            verifyState: [
                ['info', this.$t('account.verify_state.unsubmitted')],
                ['danger', this.$t('account.verify_state.fail')],
                ['primary', this.$t('account.verify_state.checking')],
                ['success', this.$t('account.verify_state.success')]
            ]
        }
    },
    created: function() {
        this.fatchData()
    },
    methods: {
        fatchData: async function () {
            const { data } = await this.$request.get('user/info')
            this.$store.commit('userEmail', data.email)
            this.$store.commit('userId', data.id)
            this.$store.commit('userVerified', data.verified)
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>